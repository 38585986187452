import React, { Suspense, useEffect, useState, lazy } from "react";

// Libraries
import { Routes, Route, useLocation } from "react-router-dom";
import retina from "retinajs";
import { AnimatePresence } from "framer-motion";

// Context
import GlobalContext from "./Context/Context";

// Components
import ScrollToTopButton from "./Components/ScrollToTop";

const AestheticZoneIndex = lazy(() =>
  import("./Pages/Index/AestheticZoneIndex")
);
const AboutUs = lazy(() => import("./Pages/Index/About"));
const MainFooter = lazy(() => import("./Pages/Index/Footer"));
const ContactUs = lazy(() => import("./Pages/Index/ContactUs"));
const NewsPage = lazy(() => import("./Pages/Index/NewsPage"));
const SingleNewsPage = lazy(() => import("./Pages/Index/SingleNewsPage"));
const SingleProductPage = lazy(() => import("./Pages/Index/SingleProduct"));

const Loading = lazy(() => import("./Pages/Index/Loading"));
const NotFound = lazy(() => import("./Pages/Index/NotFound"));

const CategoryMainPage = lazy(() =>
  import("./Pages/Index/Components/CategoryPage")
);
const LandingPage = lazy(() => import("./Pages/Landing"));

function App() {
  const [headerHeight, setHeaderHeight] = useState(0);
  const [footerHeight, setFooterHeight] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [sliderLoaded, setSliderLoaded] = useState(false);

  const [customModal, setCustomModal] = useState({
    el: null,
    isOpen: false,
  });
  const location = useLocation();

  // RetinaJS
  useEffect(() => {
    window.addEventListener("load", retina(document.querySelectorAll("img")));
  }, []);

  useEffect(() => {
    setTimeout(() => {
      import("./Functions/Utilities").then((module) => {
        module.SetHeaderMenuPos();
        module.setDocumentFullHeight();
      });
    }, 1000);
  }, [location]);
  useEffect(() => {
    // Wait for the section divs to load before scrolling
    setTimeout(() => {
      const section = location.hash.slice(1);
      if (section) {
        const sectionElement = document.getElementById(section);
        if (sectionElement) {
          window.scrollTo({
            top: sectionElement.offsetTop,
            behavior: "smooth",
          });
        }
      }
    }, 30);
  }, [location]);
  useEffect(() => {
    if (isModalOpen === true) {
      document.querySelector("body").classList.add("overflow-hidden");
    } else {
      document.querySelector("body").classList.remove("overflow-hidden");
    }
  }, [isModalOpen]);

  // Get the current location and set the window to top
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant",
    });
    setFooterHeight(0);
    setCustomModal({
      ...customModal,
      el: null,
      isOpen: false,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <GlobalContext.Provider
      value={{
        headerHeight,
        setHeaderHeight,
        footerHeight,
        setFooterHeight,
        isModalOpen,
        setIsModalOpen,
        customModal,
        setCustomModal,
        sliderLoaded,
        setSliderLoaded,
      }}
    >
      <div
        className="App"
        style={{ "--header-height": headerHeight ? headerHeight : `17vh` }}
      >
        {
          <main style={{ marginTop: 0, marginBottom: footerHeight }}>
            <ScrollToTopButton />
            <AnimatePresence mode="wait">
              <Suspense fallback={<Loading></Loading>}>
                <Routes>
                  <Route path="/dd" element={<LandingPage />} />
                  <Route path="/" element={<AestheticZoneIndex />} />
                  <Route path="/about" element={<AboutUs />} />
                  <Route path="/contact" element={<ContactUs />} />
                  <Route path="/news" element={<NewsPage />} />
                  <Route path="/news/:id" element={<SingleNewsPage />} />

                  <Route path="/products/:id" element={<SingleProductPage />} />
                  <Route
                    path="/categories/:id"
                    element={<CategoryMainPage />}
                  />

                  <Route path="*" element={<NotFound />} />
                </Routes>
              </Suspense>
            </AnimatePresence>
            <MainFooter className="bg-[#fff] text-slateblue" />
          </main>
        }
      </div>
    </GlobalContext.Provider>
  );
}

export default App;
